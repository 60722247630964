import { addDays, nextSaturday, nextSunday } from "date-fns";
import { dateToSearchFormat } from "../../../../_Web/pages/Metasearch/utils";
import {
  BookingComSearchRegions,
  Destination,
} from "../../../Services/metasearch";

export interface DiscoverCityProps {
  title: string;
  action: string;
  image: string;
}

export default function DiscoverCity({
  title,
  action,
  image,
}: DiscoverCityProps) {
  const handleClick = async () => {
    try {
      // Estrai solo il nome della città dal parametro action (rimuovi regione e paese)
      const cityName = action.split(",")[0].trim();

      // Ottieni il dest_id corretto per la città
      const destinations = await BookingComSearchRegions({
        query: cityName,
      });

      // Trova la destinazione corrispondente
      const destination = destinations.find(
        (d) => d.city_name.toLowerCase() === cityName.toLowerCase(),
      );

      if (!destination) {
        console.error("Città non trovata");
        return;
      }

      // Calcola le date
      const today = new Date();
      const startDate = nextSaturday(addDays(today, 7));
      const endDate = nextSunday(startDate);

      // Formatta le date nel formato richiesto (YYYY-MM-DD)
      const arrival_date = dateToSearchFormat(startDate);
      const departure_date = dateToSearchFormat(endDate);

      // Costruisci i parametri di ricerca
      const searchParams = {
        languagecode: "it",
        dest_label: cityName,
        dest_id: destination.dest_id,
        search_type: "city",
        arrival_date,
        departure_date,
        adults: "2",
        categories_filter: [
          "property_type::204",
          "property_type::203",
          "property_type::205",
          "property_type::208",
          "property_type::225",
          "property_type::206",
          "property_type::221",
          "property_type::226",
        ].join(","),
        sort_by: "bayesian_review_score",
      };

      // Costruisci l'URL
      const queryString = new URLSearchParams(searchParams).toString();
      const searchUrl = `${
        process.env.REACT_APP_FRONTEND_URI || "http://localhost:3000"
      }/directsearch/search?${queryString}`;

      // Naviga alla pagina di ricerca
      window.location.href = searchUrl;
    } catch (error) {
      console.error("Errore durante la ricerca della città:", error);
    }
  };

  return (
    <div
      className="clear-panel rounded position-relative overflow-hidden cursor-pointer"
      onClick={handleClick}
      style={{
        minWidth: "314px",
        width: "314px",
        height: "200px",
      }}
    >
      <div className="panel-shadow"></div>
      <h2
        className="fs-body bg-white rounded-3 position-absolute"
        style={{ padding: "2px 12px", top: "14px", left: "15px" }}
      >
        {title}
      </h2>
      <img src={image} alt={title} height="100%" />
    </div>
  );
}
